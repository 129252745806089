





























































import LandingSection from '@/components/LandingSection.vue'
import CurveWaterWaves from '@/components/CurveWaterWaves.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LandingSectionAffiliations',

  components: {
    LandingSection,
    CurveWaterWaves,
  },

  setup() {
    return {}
  },
})
